:root {
    --cartFooterHeight: 274px;
}

body {
    font-family: 'Rubik';
}

.site-body-wrap {
    overflow-x: hidden;
}

.menu-block {
    .menu-dropdown-root,
    .site-menu-row .menu-general .menu-general-root-link {
        font-size: 15px;
    }

    .menu-general {
        border-radius: 5px;
    }
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.page-title, .page-title-row {
    .main-title {
        font-size: 36px;
        line-height: 130%;
    }
}


.site-head-inner {
    z-index: auto;

/*    .site-head-menu-col {
        transition: .3s;
    }*/
}

.menu-general-wrap {

    .col-searc-block-menu {
        position: absolute;
        right: 0;
        transition: .3s;
        border: 0;

        .search-block-catalogmenu {
            margin-right: 1px;

            .site-head-search-btn {
                padding: 0;
            }
        }
    }

    .col-searc-block-menu.open {
        flex-basis: 100%;
        max-width: 100%;
        width: 99.6%;
        transition: .3s;
    }
}

.wishlist-header-block {
    position: relative;
    width: 27px;
    height: 32px;
    margin-right: 6px;

    .wishlist-header-block-link:hover {
        text-decoration: none;
    }

    .wishlist-header-icon {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        border: 1px solid transparent;
        /*svg {
            fill: currentColor;
            stroke: currentColor;
        }*/
    }

    .wishlist-header-block-count {
        position: absolute;
        right: -8px;
        top: -10px;
        min-width: 26px;
        height: 26px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        border: 3px solid white;
        box-sizing: border-box;
        padding-left: 3px;
        padding-right: 3px;
    }
}

.compare-header-block {
    position: relative;
    width: 32px;
    height: 32px;

    .compare-header-block-link:hover {
        text-decoration: none;
    }

    .compare-header-icon {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        border: 1px solid transparent;
        /*svg {
            fill: currentColor;
            stroke: currentColor;

            path {
                &:last-child {
                    stroke: none;
                }
            }
        }*/
    }

    .compare-header-block-count {
        position: absolute;
        right: -8px;
        top: -10px;
        min-width: 26px;
        height: 26px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border: 3px solid white;
        box-sizing: border-box;
        padding-left: 3px;
        padding-right: 3px;
    }

    .compare-header-block-count.hidden {
        display: none;
    }
}

.site-head .site-head__menu,
.site-menu-row {

    .menu-general {
        display: flex;
        align-items: center;

        .menu-general-item {
            display: flex;

            &:before {
                display: none;
            }
        }

        .menu-general-sub-cell-multiple {
            .menu-general-sub-category,
            .menu-general-sub-category-parent,
            .menu-general-sub-category > a {
                font-size: 14px;
            }

            .menu-general-sub-category > a {
                display: flex;
                align-items: center;
                padding: 9px 22px;
                font-size: 14px;
                font-weight: 300;
            }

            .menu-dropdown-sub-category-text,
            .menu-general-sub-category-parent.true-parent > a {
                font-weight: 500;
            }
        }

        .menu-general-sub-brand-column {

            .menu-general-sub-brand-title > a {
                font-weight: bold;
                font-size: 14px;
            }

            .menu-general-sub-category > a {
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding: 9px 22px;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .menu-general-sub-brand-title,
        .menu-general-sub-category-parent, .menu-general-root-link {
            display: flex;
            align-items: center;
            white-space: nowrap;
            padding: 9px 22px;
            font-size: 16px;
            font-weight: 300;
        }

        .menu-general-item.parent {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            &:first-child {
                border-bottom-left-radius: 0.3125rem;
            }

            &:before {
                display: none;
            }

            .menu-general-root-link {

                &:after {
                    content: '';
                    -webkit-mask-image: url(../images/menu.svg);
                    background-size: 100%;
                    width: 12px;
                    height: 7px;
                    background-repeat: no-repeat;
                    margin-left: 10px;
                    top: 0px;
                    position: relative;
                }
            }

            .menu-general-submenu.show-submenu ~ .menu-general-root-link {
                &:after {
                    transform: rotate(180deg);
                    position: relative;
                    top: 1px;
                }
            }

            .menu-general-submenu {
                border-radius: 10px;
                overflow: hidden;

                .menu-general-sub-row {
                    .menu-general-sub-column {
                        padding: 0;

                        .menu-general-category-parent {
                            margin: 0;

                            &:first-child {
                                margin-top: 0;
                            }

                            .link-text-decoration {
                                line-height: 15px;
                                text-decoration: none;
                                padding: 15px 24px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.site-head .site-head__menu {
    .menu-general {
        flex-wrap: wrap;
    }
}

.site-menu-row .menu-general {
    max-height: 48px;
    overflow: hidden;
}

.site-menu-row .menu-general.harmonica-post-calc {
    overflow: initial;
}


.products-specials-container {
    .products-specials-block {
        margin-top: 60px;

        .products-specials-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .products-specials-more {
                font-size: 12px;
            }
        }
    }
}

.article {
    margin-top: 70px;
    display: flex;
    margin-bottom: 40px;

    .article-left {
        flex: 65% 1 1;
        padding-right: 70px;

        .article-title {
            font-size: 24px;
            margin-bottom: 25px;
            line-height: 130%;
        }

        .article-text {
            font-size: 14px;
            line-height: 160%;
        }

        .article-href {
            margin-top: 25px;
            font-size: 14px;
        }
    }

    .article-img {
        flex: 35% 1 1;
    }
}

.article-block-oneColumn {
    display: flex;
    margin-top: 60px;
    margin-bottom: 70px;

    .article {
        flex: 50% 1 1;
        margin: 0;
    }

    .article-block-oneColumn-image {
        flex: 50% 1 1;
        text-align: center;
    }
}

.articles {
    margin-top: 80px;
    margin-bottom: 85px;

    .articles-title {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .articles-items {
        display: flex;

        .articles-item {
            flex: 33% 1 1;
            margin-right: 40px;

            &:last-child {
                margin-right: 0px;
            }

            .articles-item-img {
                margin-bottom: 30px;
                max-height: 125px;
                height: 125px;
            }

            .articles-item-title {
                font-size: 18px;
                margin-bottom: 18px;
            }

            .articles-item-text {
                font-size: 14px;
                line-height: 160%;
                height: 110px;
                overflow: hidden;
            }
        }
    }
}

.articles-oneColumn {
    margin-top: 60px;
    margin-bottom: 70px;

    .articles-oneColumn-title {
        font-size: 24px;
        margin-bottom: 40px;
        line-height: 130%;
    }

    .articles-oneColumn-container {
        display: flex;

        .articles-oneColumn-left {
            flex: 50% 1 1;
        }

        .articles-oneColumn-right {
            flex: 50% 1 1;
            display: flex;

            .articles-oneColumn-right-item {
                flex: 50% 1 1;

                .articles-oneColumn-right-item-img {
                    max-height: 148px;
                    height: 148px;
                }

                .articles-oneColumn-right-title {
                    font-size: 18px;
                    margin-bottom: 18px;
                }

                .articles-oneColumn-right-text {
                    font-size: 14px;
                }
            }
        }
    }
}



.menu-dropdown-root,
.menu-general-root-link {
    font-size: 16px;
    font-weight: 300;
}

.col-p-v, .desktop-col-p-v {
    padding-top: 12px;
    padding-bottom: 12px;
}


.body-content {
    .site-body-aside {
        padding-right: 12px;
    }
}


.catalog-left-column {
    .menu-dropdown {
        margin-bottom: 20px;

        .menu-dropdown-list {
            margin-bottom: 0;
        }
    }
}

/*.catalog-right-column {*/
    .products-view-sort {
        .products-view-sort-text {
            font-size: 14px;
            margin-right: 8px;
        }

        .products-view-sort-select {
            select {
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
            }

            &:after {
                content: '';
                background: url(../images/select-arrow.svg);
                background-repeat: no-repeat;
                width: 12px;
                height: 7px;
            }
        }
    }

    .products-view-sort-result {
        font-size: 14px;
    }
/*}*/



/*.products-view-variants {
    padding-top: 4px;
}*/


.carousel-main-wrap {
    .carousel-horizontal {
        .carousel-nav {
            display: none;
        }
    }

    .carousel:hover .carousel-nav {
        display: grid;
    }
}

.carousel-nav-not-show {
    .carousel-nav {
        display: none !important;
    }
}

.custom-input-text-custom {
    display: none;
}

.product-view-action {
    position: absolute;
    top: 5px;
    left: 5px;

    .products-view-compare {
        margin-bottom: 3px;
        visibility: hidden;

        .compare-control {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .custom-input-text-custom {
                display: none;
            }

            .custom-input-native {
                display: none;
            }

            .svg {
                width: 32px;
                height: 32px;
                line-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                svg {
                    width: 21px;
                    height: 21px;
                    /*path {
                        &:last-child {
                            stroke: none;
                        }
                    }*/
                }
            }
        }
    }

    .products-view-compare.compare-state-add {
        visibility: visible;

        .custom-input-text-custom {
            position: absolute;
            left: 36px;
            padding: 1px 2px;

            .compare-text-added {

                a {
                    display: block;
                }
            }
        }

        svg {
            -webkit-animation-name: pulse;
            animation-name: pulse;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }

    .products-view-compare.compare-state-not-add {
        svg {
            -webkit-animation-name: negative-pulse;
            animation-name: negative-pulse;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }


    .products-view-wishlist {
        margin-bottom: 3px;
        visibility: hidden;

        .wishlist-control {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .custom-input-native {
                display: none;
            }

            .custom-input-text-custom {
                display: none;
            }


            .svg {
                width: 32px;
                height: 32px;
                line-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                /*svg {

                    path {
                        &:last-child {
                            stroke: none;
                        }
                    }
                }*/
            }
        }
    }

    .products-view-wishlist.wishlist-state-add {
        visibility: visible;

        .custom-input-text-custom {
            position: absolute;
            left: 36px;
            padding: 1px 2px;

            .wishlist-text-added {

                a {
                    display: block;
                }
            }
        }

        svg {
            -webkit-animation-name: pulse;
            animation-name: pulse;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }

    .products-view-wishlist.wishlist-state-not-add {
        svg {
            -webkit-animation-name: negative-pulse;
            animation-name: negative-pulse;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }

    .products-view-quickview {
        .svg {
            width: 32px;
            height: 32px;
            line-height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }
    }
}

.products-view-block .products-view-item {
    &:hover {
        .product-view-action {
            display: block;
        }

        .products-view-compare.compare-state-add .custom-input-text-custom {
            display: block;
        }

        .products-view-quickview {
            display: block;
            position: initial;
            transform: none;
            opacity: 1;
            visibility: visible;
        }

        .products-view-compare {
            visibility: visible;
        }

        .products-view-wishlist {
            visibility: visible;
        }
    }
}


#modalAddress {
    .modal-content {
        padding: 20px;

        .form-field-name {
            font-size: 14px;
        }
    }

    .modal-header {
        padding: 15px 20px;
    }
}

.product-card.product-card--quickview {

    .page-title-row {
        .details-title {
            a {
                font-size: 26px;
            }
        }
    }
}

@media screen and (min-width: 48em) {
    .product-card.product-card--quickview {
        .product-card-photos {
            flex-basis: 41.6666666667%;
            max-width: 41.6666666667%;
        }

        .product-card-info {
            flex-basis: 58.3333333333%;
            max-width: 58.3333333333%;
        }
    }
}


.err-code {
    font-size: 11rem;
}


.gallery-photos-video {
    display: flex;

    .details-carousel-item-inner {
        margin-right: 8px;

        .details-photos__trigger {
            width: 100px;
            max-width: 100px;
            flex-basis: 100px;
            height: 87px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .details-carousel-item-video {
        flex: 0;
    }

    .gallery-photos {
        display: inline-block;
        vertical-align: middle;
        flex: 1 1 0;
    }

    .carousel-nav {
        .carousel-nav-prev {
            left: -20px;
        }

        .carousel-nav-next {
            right: -12px;
        }
    }
}

.gallery-photos-video {
    display: block;
}

#modalQuickView {
    .gallery-block {
        .gallery-photos-video {
            .carousel-nav {
                .carousel-nav-prev {
                    left: 0;
                }

                .carousel-nav-next {
                    right: 0;
                }
            }
        }
    }

    .products-view-price-yellow-price {
        display: inline-block;
    }

    .details-availability {
        display: block;
    }
}


.static-block-email-header {
    font-size: inherit;
}

.forgot-container {
    padding-right: 0;
    padding-left: 0;

    &__form-block {
        padding-top: 1.25rem;

        .forgot-container-field {
            margin-bottom: 24px;
        }

        .btn-forgot {
            border-radius: 4px;
            height: 48px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
        }
    }

    .forgot-container__description-block {
        .forget-password-note {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
        }
    }
}
/*.custom-field {
    margin-bottom: 20px;
}*/

.registration-block {

    .registration-block-item-checkbox {
        display: block;
        margin-bottom: 20px;
    }

    .news-subscription-checkbox {
        display: flex;
        align-items: baseline;

        &__input-wrap {
            margin-top: 0;
            margin-bottom: 0;
        }

        &__text {
            font-size: 0.75rem;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .block-alt {
        .title {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 130%;
        }

        .new-user-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 24px;
        }
    }
}


.select-custom.icon-down-open-after-abs {
    select {
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 14px;
    }

    &:after {
        content: '';
        -webkit-mask-image: url(../images/select-arrow.svg);
        mask-image: url(../images/select-arrow.svg);
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
    }
}

label {
    .custom-input-radio {
        width: 24px;
        height: 24px;
        box-shadow: none;
        top: 0;
    }

    .custom-input-native:checked ~ .custom-input-radio {
        width: 24px;
        height: 24px;
        box-shadow: none;

        &:after {
            width: 10px;
            height: 10px;
        }
    }
}

/*modal*/
.adv-modal,
.adv-modal-floating {
    .modal-content {
        /*padding: 50px 20px 30px 20px;*/
    }

    .adv-modal-close {
        left: auto;
        top: 17px;
        right: 17px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-bottom-left-radius: 0.3125rem;
        
        &:after {
            content: '';
            mask-image: url(../images/zone-close.svg);
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            margin: 0;
            position: relative;
        }
    }
}
/*.adv-modal-inner {
    width: 760px;
}*/



.modal-content {
    padding: 0;
}



/*phones-number*/

.toolbar-top {
    .phones-number {

        &__tel {
            padding-left: 8px;
            padding-right: 8px;

            & > a {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
}

.phones-number {

    &__tel {
        padding-left: 8px;
        padding-right: 8px;

        & > a {
            font-size: 16px;
            /*font-weight: 300;*/
        }
    }

    &__descr {
        padding: 0 0 0 10px;
        display: block;
        font-size: 13px;
        color: #999;
    }

    &__order-call {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 13px;
        text-align: left;
    }

    &__icon {
        display: flex;

        & > svg {
            height: 100%;
        }
    }

    &__value {
        display: flex;
        align-items: center;
    }

    &__dropdown {
        display: block;
        padding: 0 18px;

        &:hover .phones-number__list {
            display: block;
            opacity: 1;
            visibility: visible;
        }

        .phones-number__list {
            position: absolute;
            top: 100%;
            left: 0;
            cursor: auto;
            z-index: 1;
            min-width: 100%;
        }
    }

    &__field-dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        font-size: 16px;
        white-space: nowrap;
    }

    &__text a, &__text a:hover {
        text-decoration: none;
        color: #333;
    }

    &__callback-link {
        font-weight: 300;

        a:hover {
            text-decoration: underline;
        }
    }

    &__list {
        visibility: hidden;
        margin: 0;
        padding: 0;
        border-radius: 10px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        transition: opacity .5s;
        opacity: 0;
    }

    &__list.is-open {
        position: absolute;
        top: 100%;
        display: block;
        visibility: visible;
    }

    &__list-item {
        list-style-type: none;
        padding-top: 21px;
        padding-bottom: 18px;
        padding-left: 11px;
        padding-right: 11px;
        border-bottom-style: solid;
        border-bottom-width: 1px;

        &:last-child {
            border-bottom: 0;
        }

        .phones-number__field-dropdown {
            cursor: auto;

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__trigger {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        pointer-events: none;
    }

    &__trigger.phones-number__trigger--top {
        align-self: flex-start;
    }
}
/*site-head*/
.site-head {
    &__item-wrap {
        padding-top: 26px;
        padding-bottom: 14px;
    }

    .site-head-cart {
        display: flex;
        flex-direction: column-reverse;
        position: relative;

        .site-head-cart-code-text {
            display: none;
        }

        .site-head-cart-code {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }


    .compare-header-block__name,
    .wishlist-header-block__name {
        display: none;
    }

    .site-head__item-wrap.site-head__item-wrap--menu-search-tel {
        align-items: flex-end;
        padding-bottom: 26px;
        padding-top: 28px;
        flex-wrap: nowrap;

        .site-head-logo-block {
            align-self: center;
        }

        .phones-number__dropdown,
        .menu-general .menu-general-item .menu-general-root-link {
            padding-top: 0;
            padding-bottom: 0;
        }

        .site-head-search-input-wrap {
            padding-top: 0;
        }

        .site-head__center-block-inner {
            align-items: flex-end;
        }

        .phones-number {
            &__dropdown {
                padding-bottom: 3px;
            }
        }

        .site-head__user-control {
            padding-top: 10px;
        }
    }

    .site-head__item-wrap.site-head__item-wrap--header-category {
        .site-head-cart,
        .compare-header-block,
        .wishlist-header-block {
            text-align: center;
            width: auto;
            height: auto;
            padding-left: 8px;
            padding-right: 8px;
            margin: 0;
        }

        .site-head-cart-code {
            bottom: -20px;
        }

        .site-head__popover-wrap {
            display: none;
        }

        .wishlist-header-block {
            & > a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:hover {
                    text-decoration: none;
                }
            }

            .wishlist-header-icon {
                position: relative;
            }

            &__name {
                display: block;
            }
        }

        .compare-header-block {

            & > a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:hover {
                    text-decoration: none;
                }
            }

            .compare-header-icon {
                position: relative;
            }

            &__name {
                display: block;
            }
        }



        .cart-mini {
            .cart-mini-main {
                flex-direction: column;

                &__text {
                    font-size: 13px;
                }

                &-icon {
                    height: 32px;
                }

                &-count {
                    top: -9px;
                }
            }
        }
    }

    &__user-control {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__center-block-inner {
        flex-wrap: nowrap;
    }

    &__right-block {
        &:empty {
            display: none;
        }

        display: flex;
        flex-grow: 0;
        margin: 0;
    }

    &__left-block,
    &__center-block {
        margin: 0;
    }

    &__center-block {
        .menu-dropdown-list {
            min-width: 200px;
        }
    }

    &__menu {
        position: relative;
        z-index: 2;

        .menu-general {
            height: 40px;
            overflow: hidden;
            opacity: 0;
            white-space: normal;

            .menu-general-item:first-child .menu-general-root-link {
                padding-left: 0;
            }

            .menu-general-item.cs-selected {
                text-decoration: underline;
            }
        }

        .menu-general.harmonica-post-calc {
            height: auto;
            overflow: initial;
            opacity: 1;
            flex-wrap: nowrap;
        }

        .menu-general .menu-general-item {
            .menu-general-root-link {
                padding: 0 12px;
                font-weight: normal;

                &:hover .menu-general-root-link-text {
                    text-decoration: underline;
                }

                &::after {
                    margin-right: 10px;
                }
            }
        }
    }

    &__info {
        flex-wrap: nowrap;

        .site-head__address,
        .site-head__working-hours {
            padding-left: 10px;
            padding-right: 10px;
        }

        .phones-number {
            &__dropdown {
                display: block;
                padding: 0 10px;
            }
        }

        .site-head__dropdown-phones-wrap {
            flex-grow: 0;
        }
    }

    &__search-wrap.site-head__search-wrap--icon-mode {
        width: 46px;
        height: 48px;
        flex-basis: 46px;
        max-width: 46px;
        flex-shrink: 0;


        &:focus-within {
            .site-head-search-input {
                padding-left: 30px;
                padding-right: 10px;
                visibility: visible;
            }

            .site-head-search-input-wrap {
                width: 100%;
                /*z-index: 2;*/
            }

            .site-head-search-example {
                display: block;
            }
        }

        .site-head-search-form-not-empty {
            .site-head-search-input {
                padding-left: 30px;
                padding-right: 10px;
                visibility: visible;
            }

            .site-head-search-input-wrap {
                z-index: 2;
                width: 100%;
            }

            .site-head-search-example {
                display: block;
            }
        }

        .site-head-search-example {
            position: absolute;
            left: 10px;
            right: 46px;
            display: none;
            width: 100%;
            white-space: nowrap;
            top: 50%;
            transform: translateY(calc(-50% + 35px));
        }

        .site-head-search-input {
            visibility: hidden;
            transition: visibility 0.3s;
            width: calc(100% - 46px);
            padding-left: 20px;
            padding-right: 0;
            position: absolute;
            right: 46px;
            left: 0;
            box-sizing: border-box;
            padding-left: 0;
            padding-right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .site-head-search-input-wrap {
            position: absolute;
            right: -10px;
            box-sizing: border-box;
            padding-left: 0;
            padding-right: 0;
            width: 46px;
            height: 86px;
            transition: width 0.3s;
            transform: translateY(-50%);
            padding-top: 20px;
            padding-bottom: 20px;
            bottom: 0;
            top: -3px;
            height: 100%;
            transform: translate(0);
            z-index: 2;
        }

        .site-head-site-head-search {
            position: static;
        }


        .site-head-search-form {
            margin: 0;
        }


        .site-head-search-btn {
            left: auto;
            right: 0;
            z-index: 2;
            top: 50%;
            transform: translateY(-22px);
        }

        .site-head-search-form {
            position: static;
        }
    }
}


@media(max-width: 1279px) {

    .site-head {
        .site-head__item-wrap.site-head__item-wrap--header-category {

            .compare-header-block {
                &__name {
                    display: none;
                }
            }

            .wishlist-header-block {
                &__name {
                    display: none;
                }
            }

            .site-head__popover-wrap {
                display: block;
            }
        }
    }
}

.subscribe-block-field {
    text-align: center;
}
/*callback module*/
.callback-modal {
    .vertical-interval-middle {
        margin-top: 0;
        margin-bottom: 7px;
    }
}
/*cart-popup-modal*/
.adv-modal-inner.cart-popup-modal {
    max-width: 845px !important;
}
/*logo-generator-modal*/
.logo-generator-modal {
    .vertical-interval-middle {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .form-field-name {
        font-size: 14px;
    }
}


.details-param-value .quantity-discount-right {
    height: auto;
    margin-top: 0;
    margin-left: 10px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background: #b51e1e;
        height: 100%;
        width: 2.375rem;
        left: 0;
        z-index: -1;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
}

.products-view-tile .products-view-price-block {
    justify-content: flex-start !important;
}