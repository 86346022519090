.sticky-element {
    position: sticky;
    z-index: -1;
    box-shadow: none;
    background-color: transparent;
    visibility: hidden;
    height: 0;


    .fixed-header__inner {
        height: 0;
        overflow: hidden;
        transition: height 1s;
        padding: 0;
    }
}

.sticky-element.is-pinned {
    position: sticky;
    z-index: 99;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.136);
    background-color: #fff;
    visibility: visible;
    margin-bottom: 0;
    top: -1px;

    .fixed-header__inner {
        height: 60px;
        box-sizing: border-box;
        padding-top: 6px;
        padding-bottom: 6px;
    }
} 


