.registration-block {
    padding-left: 0;
    padding-right: 0;

    .form-field-control {
        .form-field-name-wrap,
        .form-field-input-wrap {
            flex-basis: 100%;
            max-width: 100%;
        }

        .form-field-checkbox-empty-label-wrap {
            display: none;
        }
    }

    .registration-bonus-form-control {
        .registration-bonus-form-name-wrap {
            display: none;
        }
    }

    .news-subscription-checkbox {
        &__input-wrap {
            order: -2;
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: auto;
            max-width: none;
        }

        &__text-wrap {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .registration-bonus-form-input-wrap {
        padding-top: 10px;
        padding-bottom: 5px;
    }



    .registration-block-text-explain-inner {
        background-color: transparent;
    }
}

.registration-block-partner-btn-inner {
    margin-left: 0;
    flex-basis: 100%;
    max-width: 100%;
}

@media screen and (min-width: 48em) {

    .registration-block {
        .registration-block-text-explain {
            flex-basis: 41.6666666667%;
            max-width: 41.6666666667%;
        }

        .registration-block-submit-btn-inner {
            margin-left: 0;
        }
    }
}

@media screen and (min-width: 768px) {

    .registration-block-text-explain-inner .title,
    .registration-block-text-explain-inner .new-user-text {
        color: currentColor !important;
    }
}
